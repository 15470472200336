import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { withRouter, useParams, useHistory, useLocation, Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, CardTitle, Label, Table, Badge, Input, UncontrolledPopover, PopoverBody, PopoverHeader } from "reactstrap";
import mime from "mime-types";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructErrorMessage, isNullOrUndefined, clearHistoryState, formatAmountWithCurrency } from '../../helpers/utils';

import { getMessageTypeAndDirectionString, getMessageBadgeCssClassName, getMessageErrorCodeDescription } from './utils';

import {
  getMessageGroupDetails,
  getMessageGroupDetailsNoLoading,
} from "../../store/actions";

import { MESSAGE_DETAILS_REFRESH_SEC } from "../../config";

const MessageGroupDetails = () => {

  const timerRef = useRef(null);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { messageGroupId: currentMessageGroupId } = useParams();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const messageGroupDetails = useSelector((state) => state.Message?.messageGroupDetails);
  const isLoading = useSelector((state) => state.Message.loading);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const messageAPIError = useSelector((state) => state.Message.error);

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  useEffect(() => {
    if (!messageGroupDetails) {
      return;
    }
    if (messageGroupDetails && (
      messageGroupDetails.status === 'queueing' ||
      messageGroupDetails.status === 'queued' ||
      messageGroupDetails.status === 'sending' ||
      messageGroupDetails.status === 'sent' ||
      messageGroupDetails.status === 'partially_delivered')
    ) {

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        dispatch(getMessageGroupDetailsNoLoading(currentProjectId, currentMessageGroupId));
      }, (MESSAGE_DETAILS_REFRESH_SEC ?? 3) * 1000);
    }
  }, [dispatch, currentProjectId, currentMessageGroupId, messageGroupDetails]);

  useEffect(() => {
    if (currentProjectId && currentMessageGroupId) {
      dispatch(getMessageGroupDetails(currentProjectId, currentMessageGroupId));
    }
  }, [dispatch, currentProjectId, currentMessageGroupId]);

  useEffect(() => {

    if (redirectStatus === 'create-message-group-succeeded') {
      setSuccessMessage("Your message was queued for delivery");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);
  }, [redirectStatus, history]);

  // this useEffect should be without any dependencies to work as "unload" func
  useEffect(() => {
    return () => {
      // Clear the timeout when the component unmounts
      clearTimeout(timerRef.current);
    };
  }, []);

  const constructMessageGroupTo = (mg) => {
    if (!mg) {
      return "";
    }

    return (mg?.totalCount > 0 ? mg?.totalCount : "-") + (mg?.totalCount > 1 ? " recipients" : (mg?.totalCount === 1) ? " recipient" : "");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="SMS/MMS"
            titleUrl={`/projects/${currentProjectId}/messages/groups`}
            breadcrumbItem="Message Details"
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={isLoading} />
                  {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
                  {!isLoading && messageAPIError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(messageAPIError)}</CustomAlert> : null}
                  {!isLoading &&
                    <div className="message-group-details-form">
                      <Row>
                        <Col md={4}>
                          <Label className="form-label">Message ID</Label>
                          <p>{messageGroupDetails?.messageGroupId}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Status</Label>
                          <p>
                            <span className='text-uppercase h5'>
                              <Badge className={getMessageBadgeCssClassName(messageGroupDetails) + " rounded-pill"}>
                                {messageGroupDetails?.status ? messageGroupDetails?.status.replaceAll("_", " ") : ""}
                              </Badge>
                            </span>
                          </p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Type</Label>
                          <p>
                            <span className='text-uppercase h5'>
                              <Badge className="rounded-pill">
                                {getMessageTypeAndDirectionString(messageGroupDetails)}
                              </Badge>
                            </span>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Label className="form-label">From</Label>
                          <p>
                            {messageGroupDetails?.fromTelephoneNumberId && <Link to={`/projects/${currentProjectId}/numbers/${messageGroupDetails?.fromTelephoneNumberId}`} target="_blank" title="Go to telephone number details">
                              {messageGroupDetails?.fromTelephoneNumber}
                            </Link>}
                            {!messageGroupDetails?.fromTelephoneNumberId && <>
                              {messageGroupDetails?.fromTelephoneNumber}
                            </>}
                          </p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">To</Label>
                          <p>
                            {messageGroupDetails?.totalCount === 1 && messageGroupDetails?.toTelephoneNumbers?.length === 1 && <>
                              {messageGroupDetails?.toTelephoneNumbers[0]}{/* toTelephoneNumbers.length should be always 1 for totalCount == 1 */}
                            </>}
                            {(messageGroupDetails?.totalCount > 1 || (messageGroupDetails?.totalCount === 1 && messageGroupDetails?.toTelephoneNumbers?.length !== 1)) && <>
                              <span id="message-group-details-to-list" className="dotted-line-text cursor-pointer">{constructMessageGroupTo(messageGroupDetails)}</span>
                              {/* popover needs to be within the same "if" as a target */}
                              <UncontrolledPopover
                                target="message-group-details-to-list"
                                trigger="hover"
                                placement="top"
                              >
                                <PopoverHeader>Addressees</PopoverHeader>
                                <PopoverBody>
                                  {Array.isArray(messageGroupDetails?.toContacts) && messageGroupDetails?.toContacts.length > 0 && <>
                                    <strong>Contacts</strong>
                                    {messageGroupDetails?.toContacts.map((c, i) => <div key={i}>
                                      <Link to={`/projects/${currentProjectId}/directory/contacts/${c.contactId}`} target="_blank">{c.fullName ?? `${c.firstName} ${c.lastName}` ?? c.phoneNumber ?? c.contactId}</Link>
                                    </div>)}
                                  </>}
                                  {Array.isArray(messageGroupDetails?.toContactGroups) && messageGroupDetails?.toContactGroups.length > 0 && <>
                                    <strong>Groups</strong>
                                    {messageGroupDetails?.toContactGroups.map((g, i) => <div key={i}>
                                      <Link to={`/projects/${currentProjectId}/directory/contacts/groups/${g.contactGroupId}`} target="_blank">{g.title}</Link>
                                    </div>)}
                                  </>}
                                  {Array.isArray(messageGroupDetails?.toTelephoneNumbers) && messageGroupDetails?.toTelephoneNumbers.length > 0 && <>
                                    <strong>Numbers</strong>
                                    {messageGroupDetails?.toTelephoneNumbers.map((tn, i) => <div key={i}>
                                      {tn}
                                    </div>)}
                                  </>}
                                </PopoverBody>
                              </UncontrolledPopover>
                            </>}
                          </p>
                        </Col>
                        <Col md={4}>

                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Label className="form-label">{messageGroupDetails?.direction === "inbound" ? "Received" : "Sent"} date</Label>
                          <p>{!messageGroupDetails?.createdTimestampMs ? '' : new Date(messageGroupDetails?.createdTimestampMs).toLocaleString()}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Cost</Label>
                          <p>
                            {messageGroupDetails?.status !== 'sending' && !isNullOrUndefined(messageGroupDetails?.costAmount) && messageGroupDetails?.costAmount >= 0 &&
                              <span>{formatAmountWithCurrency(messageGroupDetails?.costAmount, messageGroupDetails?.costUnit, messageGroupDetails?.costCurrency)}</span>
                            }

                            {(messageGroupDetails?.status === 'sending' || isNullOrUndefined(messageGroupDetails?.costAmount)) &&
                              <span>N/A</span>
                            }
                          </p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Author</Label>
                          <p>{messageGroupDetails?.createdByFullName ?? "-"}</p>
                        </Col>
                      </Row>
                      {messageGroupDetails?.messageBody &&
                        <Row>
                          <Col md={12}>
                            <Label className="form-label">Message text</Label>
                            <p>
                              <Input
                                type="textarea"
                                name="messageBody"
                                value={messageGroupDetails?.messageBody || ""}
                                readOnly={true}
                                rows="10"
                              />
                            </p>
                          </Col>
                        </Row>
                      }
                      {messageGroupDetails?.media && Array.isArray(messageGroupDetails?.media) && messageGroupDetails?.media.length > 0 &&
                        <Row>
                          <Col md={12}>
                            <div><br /><br /></div>
                            <Label className="form-label">Attached media</Label>
                            <Table className="table table-striped table-responsive">
                              <tbody>
                                {messageGroupDetails?.media && Array.isArray(messageGroupDetails?.media) && messageGroupDetails?.media.length > 0 && messageGroupDetails?.media.map((md, i) => <tr key={i} className="align-middle">
                                  <td>
                                    <a href={md?.url} target="_blank" rel="noreferrer">
                                      {md?.name ? md?.name : md?.url}{md?.contentType ? "." + mime.extension(md?.contentType) : ""}
                                    </a>
                                  </td>
                                </tr>)
                                }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      }
                    </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
          {!isLoading && Array.isArray(messageGroupDetails?.itemsStatuses) && messageGroupDetails?.itemsStatuses.length > 0 && <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Status details</CardTitle>
                  <Table className="table-striped table-responsive message-group-details-form">
                    <thead>
                      <tr>
                        <th>To</th>
                        <th>Status</th>
                        <th>Notes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {messageGroupDetails?.itemsStatuses.map((s, i) => <tr key={i} className="align-middle">
                        <td>
                          {s.to.map((tn, i) => <div key={i}>
                            {tn}
                          </div>)}
                        </td>
                        <td>
                          <span className='text-uppercase'><Badge className={getMessageBadgeCssClassName(s)}>
                            {s?.status ? s?.status.replaceAll("_", " ") : ""}
                          </Badge></span>
                        </td>
                        <td>
                          <span className="text-danger">
                            {getMessageErrorCodeDescription(s.errorCode)}
                          </span>
                          {!s.errorCode && <> - </>}
                        </td>
                      </tr>)
                      }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>}
        </Container>
      </div>

    </React.Fragment>
  )
}

MessageGroupDetails.propTypes = {
  t: PropTypes.any,
  message: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    message: state.Message,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(MessageGroupDetails)));